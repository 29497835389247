<template>
  <div class="home">
    <div><a href="./stations/02336240">Johnson Rd & South Fork P'tree Creek (near Zonolite Park)</a></div>
    <div><a href="./stations/02336300">Northside Pkwy & P'tree Creek (near Memorial Park)</a></div>
    <div><a href="./stations/02336360">Rickenbacker Dr & Nancy Creek (near Blue Heron Nature Preserve)</a></div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="scss">
body {
  font-size: 18px;
}
</style>
